import $ from 'jquery'
import '../../common/flexslider'
import { Section } from '../../common/section'
import { SectionManager } from '../../common/section_manager'
import '../../common/testimonials/index.scss'

const SLIDER_ALT_3_ICON = `
<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="25" cy="25" r="24.5" stroke="black"/>
  <path d="M36.3536 25.3536C36.5488 25.1583 36.5488 24.8417 36.3536 24.6464L33.1716 21.4645C32.9763 21.2692 32.6597 21.2692 32.4645 21.4645C32.2692 21.6597 32.2692 21.9763 32.4645 22.1716L35.2929 25L32.4645 27.8284C32.2692 28.0237 32.2692 28.3403 32.4645 28.5355C32.6597 28.7308 32.9763 28.7308 33.1716 28.5355L36.3536 25.3536ZM14 25.5H36V24.5H14V25.5Z" fill="black"/>
</svg>
`

const CARET_RIGHT = `
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0" y="0" width="16" height="27" viewBox="0 0 16 27">
  <path fill="currentColor" d="M0 2.5L2.5 0 16 13.5 2.5 27 0 24.5l11-11-11-11z"/>
</svg>
`

const CARET_LEFT = `
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0" y="0" width="16" height="27" viewBox="0 0 16 27">
  <path fill="currentColor" d="M5 13.5l11 11-2.5 2.5L0 13.5 13.5 0 16 2.5l-11 11z"/>
</svg>
`

class TestimonialsSection extends Section {
  constructor(...args) {
    super(...args)
    this._setUp = this._setUp.bind(this)
  }

  static initClass() {
    this.type = 'testimonials'
  }
  onLoad() {
    this._startAt = null
    this._slideshow = this.elem.find('[data-slideshow]').data('slideshow')
    return setTimeout(this._setUp, 0)
  }

  _setUp() {
    const svgTemplate = document.createElement('template')

    svgTemplate.innerHTML = SLIDER_ALT_3_ICON
    const sliderEl = this.elem.find('.flexslider')

    const defaultSlideIcons = {
      nextText: CARET_RIGHT,
      prevText: CARET_LEFT,
    }

    const alt3SlideIcons = {
      nextText: svgTemplate.innerHTML,
      prevText: svgTemplate.innerHTML,
    }

    const slideIcons = sliderEl.hasClass(
      'testimonial__slider--slider_alternate_3'
    )
      ? alt3SlideIcons
      : defaultSlideIcons

    return (this._flexslider = sliderEl.flexslider({
      animation: 'slide',
      selector: '.flex-slides > li',
      slideshow: this._slideshow,
      ...slideIcons,
      startAt: this._startAt || 0,
      start: (slider) => {
        if (this._startAt !== null) {
          return slider.flexslider('pause')
        }
      },
    }))
  }

  onBlockSelect($blockElem) {
    const index = $blockElem.data('index')

    if (this._flexslider) {
      this._flexslider.flexslider(index)
      return this._flexslider.flexslider('pause')
    } else {
      return (this._startAt = index)
    }
  }

  onBlockDeselect() {
    if (this._flexslider) {
      if (this._slideshow) {
        return this._flexslider.flexslider('play')
      }
    } else {
      return (this._startAt = null)
    }
  }
}
TestimonialsSection.initClass()

$(function () {
  new SectionManager([TestimonialsSection])
})
